import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-grow w-full md:p-10 p-6 pb-0" }
const _hoisted_2 = { class: "max-w-5xl mx-auto h-full relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_HelpTooltip = _resolveComponent("HelpTooltip")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_Alert = _resolveComponent("Alert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view)
      ]),
      _createVNode(_component_HelpTooltip)
    ]),
    _createVNode(_component_Footer),
    _createVNode(_component_Alert)
  ], 64))
}