import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "text-base font-bold pointer-events-none" }
const _hoisted_2 = {
  key: 0,
  class: "\n        absolute\n        top-10\n        right-2\n        bg-white\n        border border-secondary\n        text-secondary\n      "
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_ctx.isNotQuestionnairePage)
    ? _withDirectives((_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "relative w-10 cursor-pointer text-secondary hover:text-secondary-100",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleLanguageDropdown()))
      }, [
        _createElementVNode("h4", _hoisted_1, _toDisplayString(this.$i18n.locale), 1),
        (_ctx.isLanguageDropdownOpen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.avaialbleLanguages, (language) => {
                return (_openBlock(), _createElementBlock("p", {
                  class: _normalizeClass(`cursor-pointer px-4 py-2 hover:bg-secondary-100 hover:text-white ${
          language.code === _ctx.activeLanguage ? 'bg-secondary text-white' : ''
        }`),
                  key: language.code,
                  onClick: ($event: any) => (_ctx.selectLanguage(language.code))
                }, _toDisplayString(language.name), 11, _hoisted_3))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ], 512)), [
        [_directive_click_outside, _ctx.closeLanguageDropdown]
      ])
    : _createCommentVNode("", true)
}