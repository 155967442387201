
import { Options, Vue } from "vue-class-component";
import Header from "./components/layout/Header.vue";
import Footer from "./components/layout/Footer.vue";
import HelpTooltip from "./components/layout/HelpTooltip.vue";
import Alert from "@/shared/components/Alert.vue";
import AuthTokenRequest from "@/shared/types/AuthTokenRequest";

@Options({
  components: {
    Header,
    Footer,
    HelpTooltip,
    Alert,
  },
})
export default class App extends Vue {}
